h1, h2, h3, h4, h5, ul, ol, p {
  margin: 0;
  padding: 0;
  line-height: 1.4;
}
a, button {
  line-height: 1 !important;
}
// Helped classes

.clear {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}
.align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.block-link {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  cursor: pointer;
}
.block-img {
  display: block;
  width: 100%;
  > img {
    display: block;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}
.image-cover {
   height: 100%;
   img {
     height: 100%;
     object-fit: cover;
   }
 }
.block-centered-content {
  .text-center;
  > a, > button {
    display: inline-block;
  }
}

.block-map, .block-video {
  width: 100%;
  height: 400px;
  margin: 0 auto;
  .map, .video {
    width: 100%;
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  @media (min-width: @screen-sm-min) {
    height: 100%;
  }
  @media (min-width: @screen-md-min) {
    //height: 601px;
  }
}
// Frames for youtube
@iframe-width-sm: @container-sm - @grid-gutter-width;
@iframe-width-md: @container-md - @grid-gutter-width;
@iframe-width-lg: @container-lg - @grid-gutter-width;
@iframe-height-sm: @iframe-width-sm / 1.78;
@iframe-height-md: @iframe-width-md / 1.78;
@iframe-height-lg: @iframe-width-lg / 1.78;
.block-video-container {
  width: 90vw;
  height: 56vw;
  @media (min-width: @screen-sm-min) {
    width: @iframe-width-sm;
    height: @iframe-height-sm;
  }
  @media (min-width: @screen-md-min) {
    width: @iframe-width-md;
    height: @iframe-height-md;
  }
  @media (min-width: @screen-lg-min) {
    width: @iframe-width-lg;
    height: @iframe-height-lg;
  }
}

// Styles for block-video in container-fluid
@iframe-width: 100vw;
@iframe-height: 100vh;
.block-video-fluid {
  width: @iframe-width;
  height: @iframe-height;
  max-width: 100%;
}
// Sticky footer with flex
//.body {
//  position: relative;
//  display: flex;
//  flex-direction: column;
//  width: 100%;
//  height: 100%;
//}
//.wrapper {
//  flex: 1 0 auto;
//}
//footer {
//  flex: 0 0 auto;
//}

// Grid classes
.flex-container {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
}
.flex-item {
  position: relative;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  // 3 items in row
  @media (min-width: @screen-sm-min) {
    width: ~'calc((100% - 2 * 10px)/3)';
    margin-left: 10px;
    &:nth-child( 3n + 1 ) {
      margin-left: 0;
    }
  }
  // 4 items in row
  @media (min-width: @screen-md-min) {
    width: ~'calc((100% - 3 * 10px)/4)';
    margin-left: 10px;
    &:nth-child( 3n + 1 ) {
      margin-left: 10px;
    }
    &:nth-child( 4n + 1 ) {
      margin-left: 0;
    }
  }
  // 5 items in row
  @media (min-width: @screen-lg-min) {
  width: ~'calc((100% - 4 * 10px)/5)';
  margin-left: 10px;
  &:nth-child( 4n + 1 ) {
    margin-left: 10px;
  }
  &:nth-child( 5n + 1 ) {
    margin-left: 0;
  }
  }
}
.flex-item-3 {
  position: relative;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  // 3 items in row
  @media (min-width: @screen-sm-min) {
    width: ~'calc((100% - 2 * 30px)/3)';
    margin-left: 30px;
    &:nth-child( 3n + 1 ) {
      margin-left: 0;
    }
  }
}
.flex-item-2-4-5 {
  position: relative;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  // 2 items in row
  @media (min-width: 500px) {
    width: 50%;
  }
  // 4 items in row
  @media (min-width: 700px) {
    width: ~'calc(100%/4)';
  }
  // 6 items in row
  @media (min-width: 1100px) {
    width: ~'calc(100%/5)';
  }
}

@link-viva-width: 60px;
@link-viva-height: 14px;
.block-link-viva {
  width: @link-viva-width;
  margin-left: auto;
  .link-viva {
    display: block;
    width: @link-viva-width;
    height: @link-viva-height;
    background-image: url("../images/viva_logo.png");
    opacity: 0.4;
    //background-color: #a6a6a6;
    //border-radius: 3px;
    //box-shadow:         0px 0px 10px 0px rgba(50, 50, 50, 1);
    &:focus, &:hover {
      opacity: 0.8;
      text-decoration: none;
      //box-shadow:         0px 0px 10px 0px rgba(70, 70, 70, 1);
    }
    &:active {
      opacity: 1;
    }
  }
}

@mail-color: #00c0ff;
@mail-font-size: 18px;
@mail-text-decoration: none;
@mail-color-hover: #00c0ff;
@mail-text-decoration-hover: underline;
.mail {
  color: @mail-color;
  font-size: @mail-font-size;
  text-decoration: @mail-text-decoration;
  &:hover, &:focus, &:active {
    color: @mail-color-hover;
    text-decoration: @mail-text-decoration-hover;
  }
}


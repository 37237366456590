////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Bower
@node_path: "../../../node_modules/";

// Font Awesome
@import "@{node_path}font-awesome/less/font-awesome.less";
// Core variables and mixins
@import "@{node_path}bootstrap/less/variables.less";
@import "@{node_path}bootstrap/less/mixins.less";

// Reset and dependencies
@import "@{node_path}bootstrap/less/normalize.less";
//@import "@{node_path}bootstrap/less/print.less";
//@import "@{node_path}bootstrap/less/glyphicons.less";

// Core CSS
@import "@{node_path}bootstrap/less/scaffolding.less";
@import "@{node_path}bootstrap/less/type.less";
//@import "@{node_path}bootstrap/less/code.less";
@import "@{node_path}bootstrap/less/grid.less";
//@import "@{node_path}bootstrap/less/tables.less";
//@import "@{node_path}bootstrap/less/forms.less";
@import "@{node_path}bootstrap/less/buttons.less";

// Components
@import "@{node_path}bootstrap/less/component-animations.less";
@import "@{node_path}bootstrap/less/dropdowns.less";
@import "@{node_path}bootstrap/less/button-groups.less";
//@import "@{node_path}bootstrap/less/input-groups.less";
//@import "@{node_path}bootstrap/less/navs.less";
//@import "@{node_path}bootstrap/less/navbar.less";
//@import "@{node_path}bootstrap/less/breadcrumbs.less";
//@import "@{node_path}bootstrap/less/pagination.less";
//@import "@{node_path}bootstrap/less/pager.less";
//@import "@{node_path}bootstrap/less/labels.less";
//@import "@{node_path}bootstrap/less/badges.less";
//@import "@{node_path}bootstrap/less/jumbotron.less";
//@import "@{node_path}bootstrap/less/thumbnails.less";
//@import "@{node_path}bootstrap/less/alerts.less";
//@import "@{node_path}bootstrap/less/progress-bars.less";
//@import "@{node_path}bootstrap/less/media.less";
//@import "@{node_path}bootstrap/less/list-group.less";
//@import "@{node_path}bootstrap/less/panels.less";
//@import "@{node_path}bootstrap/less/responsive-embed.less";
//@import "@{node_path}bootstrap/less/wells.less";
@import "@{node_path}bootstrap/less/close.less";

// Components w/ JavaScript
@import "@{node_path}bootstrap/less/modals.less";
//@import "@{node_path}bootstrap/less/tooltip.less";
//@import "@{node_path}bootstrap/less/popovers.less";
//@import "@{node_path}bootstrap/less/carousel.less";

// Utility classes
@import "@{node_path}bootstrap/less/utilities.less";
@import "@{node_path}bootstrap/less/responsive-utilities.less";

@import "www/media/css-src/parameter-mixins";
@import "www/media/css-src/helped-classes";
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

@font-family-oswald: 'Oswald', sans-serif;
@font-family-open-sans: 'Open Sans', sans-serif;
@font-family-base: @font-family-oswald;


html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  max-width: 2500px;
  margin-right: auto;
  margin-left: auto;
}
.body {
  position: relative;
}

// ------------------------------- 404
.page-404 {
  .text-center;
  width: 100%;
  height: 100%;
  color: #ffffff;
  background: #282828 url("/media/images/404.jpg") center no-repeat;
  background-size: cover;
  .inner-block {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    padding-top: 2%;
  }
  .block-logo {
    width: 80px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 5%;
  }
  .b-404 {
    .text-uppercase;
    margin-bottom: 3%;
    font-family: @font-family-oswald;
    font-size: 40px;
    font-weight: 500;
    line-height: 1.2;
    letter-spacing: 2px;
  }
  .text {
    margin-bottom: 15%;
    max-width: 350px;
    margin-right: auto;
    margin-left: auto;
    font-family: @font-family-open-sans;
    font-size: 14px;
    letter-spacing: 1.05px;
  }
  .btn-default {
    .maker-btn(18px, 25px, 13px, #ffffff, 
    #172a48,  none, 0,
          #ffffff, #284068, none,
          #ffffff, #334d7a, none);
    .text-uppercase;
    margin-top: 15px;
    font-family: @font-family-open-sans;
  }
  @media (min-width: @screen-sm-min) {
    .inner-block {
      width: 50%;
      margin-right: auto;
      margin-left: auto;
      padding-top: 10%;
    }
    .b-404 {
      font-size: 90px;
      letter-spacing: 3.97px;
    }
    .text {
      font-size: 16px;
    }
    .btn-default {
      .maker-btn(28px, 38px, 15px, #ffffff, 
      #172a48, #172a48, 0,
      #ffffff, #284068, none,
      #ffffff, #334d7a, none);
    }
  }
}

//------------------------------------------------------------------------------------

.block-logo {
  .text-center;
  width: 204px;
  .block-img {
    margin-bottom: 1.5vh;
  }
  p {
    color: #fefefe;
    font-weight: 300;
    line-height: 1.2;
  }
  .name {
    font-size: 30px;
    font-weight: 400;
  }
}
.logo-main {
  position: absolute;
  z-index: 2;
  top: 10px;
  left: 20px;
  width: 72px;
  .block-img {
    margin-bottom: 1vh;
    padding-right: 10px;
    padding-left: 10px;
  }
  .name {
    font-size: 12px;
    letter-spacing: 2.24px;
  }
  @media (min-width: @screen-md-min) {
    top: 20px;
    .block-img {
      padding-right: 0;
      padding-left: 0;
    }
  }
}
.menu-lang {
  .maker-dropdown-menu(40px, 0, 0, #000000,
          none, 0,
          0, #ffffff, 12px, 400,
          #ffffff, #424237,
          #ffffff, #424237);
  .text-uppercase;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999;
  //font-family: @font-family-yeseva;
  .dropdown-toggle {
    .text-uppercase;
    width: 40px;
    height: 40px;
    color: #ffffff;
    font-size: 12px;
    background-color: #424237;
    border: none;
    border-radius: 0;
    &:hover, &:hover, &:active, &.active {
      color: #000000 !important;
      background-color: #ffffff !important;
      border: none !important;
    }
  }
  &.open > .dropdown-toggle.btn-default {
    color: #000000 !important;
    background-color: #ffffff !important;
    border: none !important;
  }
  .dropdown-menu {
    > li > a {
      height: 35px;
    }
  }
  @media (min-width: @screen-sm-min) {
    top: 0;
    right: 0;
    .maker-dropdown-menu(70px, 0, 0, #000000,
            none, 0,
            0, #ffffff, 18px, 400,
            #ffffff, #424237,
            #ffffff, #424237);
    .dropdown-toggle {
      .text-uppercase;
      width: 70px;
      height: 70px;
      color: #ffffff;
      font-size: 18px;
      background-color: #424237;
      border: none;
      border-radius: 0;
      &:hover, &:hover, &:active, &.active {
        color: #000000 !important;
        background-color: #ffffff !important;
        border: none !important;
      }
    }
  }
}
.mail {
  display: inline-block;
  margin-bottom: 10vh;
  font-family: @font-family-open-sans;
  font-size: 14px;
  letter-spacing: 1.18px;
  @media (min-width: @screen-sm-min) {
    font-size: 16px;
  }
  @media (min-width: @screen-sm-min) {
    font-size: 18px;
  }
}
.block-social {
  > a {
    margin-right: 16px;
    .fa {
      font-size: 30px;
      @media (min-width: @screen-lg-min) {
        font-size: 40px;
      }
    }
  }
}
.block-copyright {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 10px;
  padding-left: 10px;
  background-color: #0d3b62;
  .copyright {
    padding-right: 20px;
    color: #a0a0a0;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: 0.7px;
  }
  @media (min-width: @screen-sm-min) {
    position: absolute;
    bottom: 10px;
    right: 0;
    left: 0;
    width: 80%;
    max-width: 600px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    padding-left: 0;
    background-color: transparent;
  }
  @media (min-width: @screen-lg-min) {
    bottom: 20px;
    .copyright {
      font-size: 14px;
    }
  }
}
.block-link-viva {
  padding-top: 5px;
}

.block-wrapper {
  .clear;
  position: relative;
  @media (min-width: @screen-sm-min) {
    display: flex;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
}
.slick-list {
  overflow: unset !important;
}
.slider-first, .slider-second {
  .hidden-xs;
  position: relative;
  @media (min-width: @screen-sm-min) {
    width: 50%;
    .slick-slide {
      height: 100vh !important;
    }
  }
}
.slider-first {
  z-index: 1;
}
.block-inner {
  position: relative;
  width: 100%;
  height: 100%;
}
.block-bg {
  .image-cover;
}
.block-content {
  .align-center;
  position: relative;
  @media (min-width: @screen-sm-min) {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
}
.block-text, .block-objects {
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}
.block-text {
  max-width: 600px;
}

.navigator {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: @icon-size;
}
.slick-dots {
  .align-center;
  .list-unstyled;
}
@icon-size-xs: 50px;
@icon-size: 70px;
.slick-dots li {
  .align-center;
  width: @icon-size-xs;
  height: @icon-size-xs;
  @media (min-width: @screen-lg-min) {
    width: @icon-size;
    height: @icon-size;
  }
}
.slick-dots li {
  a {
    .align-center;
    width: @icon-size-xs;
    height: @icon-size-xs;
    background: #0d3b62;
    border: 0.5px solid #8c9799;
    transition: transform 0.35s;
    .icon-slide-1 {
      width: 34px;
      height: 34px;
      background: url("../images/icons/1_w.png") no-repeat;
      background-size: auto;
      background-position: center;
    }
    .icon-slide-2 {
      width: 34px;
      height: 34px;
      background: url("../images/icons/2_w.png") no-repeat;
      background-size: auto;
      background-position: center;
    }
    .icon-slide-3 {
      width: 34px;
      height: 34px;
      background: url("../images/icons/3_w.png") no-repeat;
      background-size: auto;
      background-position: center;
    }
    .icon-slide-4 {
      width: 34px;
      height: 34px;
      background: url("../images/icons/4_w.png") no-repeat;
      background-size: auto;
      background-position: center;
    }
    .icon-slide-5 {
      width: 34px;
      height: 34px;
      background: url("../images/icons/5_w.png") no-repeat;
      background-size: auto;
      background-position: center;
    }
  }
  a:hover, a:focus, a:active,
  &.slick-active a {
    background: #ffffff;
  }
  a:hover, a:focus, a:active,
  &.slick-active a,
  &.slick-active a div:hover, &.slick-active a div:focus, &.slick-active a div:active {
    cursor: pointer;
    .icon-slide-1 {
      background: url("../images/icons/1_b.png") no-repeat ;
      background-size: auto;
      background-position: center;
    }
    .icon-slide-2 {
      background: url("../images/icons/2_b.png") no-repeat;
      background-size: auto;
      background-position: center;
    }
    .icon-slide-3 {
      background: url("../images/icons/3_b.png") no-repeat;
      background-size: auto;
      background-position: center;
    }
    .icon-slide-4 {
      background: url("../images/icons/4_b.png") no-repeat;
      background-size: auto;
      background-position: center;
    }
    .icon-slide-5 {
      background: url("../images/icons/5_b.png") no-repeat;
      background-size: auto;
      background-position: center;
    }
  }
  @media (min-width: @screen-lg-min) {
    a {
      width: @icon-size;
      height: @icon-size;
    }
  }
}
.slick-dots li a img {
  display: block;
  width: @icon-size-xs - 20px;
  height: @icon-size-xs - 20px;
  @media (min-width: @screen-lg-min) {
    width: @icon-size - 20px;
    height: @icon-size - 20px;
  }
}


.title {
  .text-uppercase;
  color: #fefefe;
  font-weight: 500;
  line-height: 1.2;
}
.text-plain {
  color: #ffffff;
  font-family: @font-family-open-sans;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 1px;
}
.block-text-center {
  .align-center;
  .text-center;
  flex-direction: column;
  .block-logo {
    width: 110px;
    margin-bottom: 5vh;
    p {
      font-size: 14px;
    }
    .name {
      font-size: 20px;
      letter-spacing: 2.24px;
    }
  }
  > p {
    .text-uppercase;
    color: #fefefe;
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 1.85px;
  }
  @media (min-width: @screen-sm-min) {
    > p {
      font-size: 25px;
      letter-spacing: 1.85px;
    }
  }
  @media (min-width: @screen-lg-min) {
    .block-logo {
      width: 180px;
      p {
        font-size: 20px;
      }
      .name {
        font-size: 30px;
        letter-spacing: 2.24px;
      }
    }
    > p {
      font-size: 45px;
      letter-spacing: 1.85px;
    }
  }
  @media (min-width: 1600px) {
    .block-logo {
      width: 200px;
    }
    > p {
      font-size: 60px;
    }
  }
}

.mobile {
  .visible-xs;
  .section-right2, .section-right3, .section-left4, .section-right5 {
    .block-content {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    .block-bg {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
  }
}
.first-block-mobile {
  padding-top: 100px;
  padding-bottom: 30px;
  background-color: #0d3b62;
}
.section-left1, .section-right1 {
  .title {
    font-size: 30px;
    letter-spacing: 2px;
  }
  .block-text-center {
    // .align-center;
    // .text-center;
    // flex-direction: column;
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    right: -150px;
    width: 300px;
    // .block-logo {
    //   width: 110px;
    //   margin-bottom: 5vh;
    //   p {
    //     font-size: 14px;
    //   }
    //   .name {
    //     font-size: 20px;
    //     letter-spacing: 2.24px;
    //   }
    // }
    // > p {
    //   .text-uppercase;
    //   color: #fefefe;
    //   font-size: 25px;
    //   font-weight: 500;
    //   letter-spacing: 1.85px;
    // }
  }
  @media (min-width: @screen-sm-min) {
    .block-text {
      padding-left: 15%;
    }
    .title {
      font-size: 50px;
      letter-spacing: 2.5px;
    }
  }
  @media (min-width: @screen-lg-min) {
    .title {
      font-size: 80px;
      letter-spacing: 2.5px;
    }
  }
  @media (min-width: 1600px) {
    .title {
      font-size: 90px;
    }
  }
}
.section-left2 {
  @media (min-width: @screen-sm-min) {
    .block-text {
      padding-left: 0;
    }
    .block-logo {
      width: 200px;
      margin-right: auto;
      margin-left: auto;
      p {
        font-size: 22px;
      }
      .name {
        font-size: 30px;
        letter-spacing: 2.24px;
      }
    }
  }
  @media (min-width: @screen-lg-min) {
    .block-logo {
      width: 350px;
      p {
        font-size: 26px;
      }
      .name {
        font-size: 35px;
        letter-spacing: 2.24px;
      }
    }
  }
  @media (min-width: 1600px) {
    .block-logo {
      width: 590px;
      p {
        font-size: 30px;
      }
      .name {
        font-size: 45px;
        //letter-spacing: 2.24px;
      }
    }
  }
}
.section-right2 {
  .text-plain {
    margin-bottom: 10px;
    font-size: 13px;
  }
  @media (min-width: @screen-sm-min) {
    text-plain {
      font-size: 15px;
    }
  }
  @media (min-width: @screen-lg-min) {
    text-plain {
      font-size: 18px;
    }
  }
}
.section-left4, .section-right3 {
    .block-content {
      flex-direction: column;
    }
  .block-text {
    margin-bottom: 7vh;
    .title {
      margin-bottom: 3vh;
      font-size: 22px;
      letter-spacing: 1px;
    }
    .text-plain {
      font-size: 12px;
    }
  }
  .block-objects {
    .title {
      margin-bottom: 3vh;
      font-size: 22px;
      font-weight: 300;
      letter-spacing: 1px;
    }
    .list-objects {
      display: flex;
      align-items: stretch;
      justify-content: flex-start;
      flex-wrap: wrap;
      .item-object {
        position: relative;
        width: 45%;
        margin-right: 3%;
        p {
          margin-top: 10px;
          color: #ffffff;
          font-family: @font-family-open-sans;
          font-size: 12px;
          font-weight: 400;
          line-height: 1.5;
          letter-spacing: 1px;
        }
      }
    }
  }
  @media (min-width: @screen-sm-min) {
    .block-text {
      .title {
        font-size: 34px;
        letter-spacing: 1.5px;
      }
      .text-plain {
        font-size: 14px;
      }
    }
    .block-objects {
      .title {
        font-size: 26px;
        letter-spacing: 1.5px;
      }
      .list-objects .item-object {
        width: 30%;
        p {
          font-size: 14px;
        }
      }
    }
  }
  @media (min-width: @screen-lg-min) {
    .block-text {
      .title {
        font-size: 60px;
        letter-spacing: 1.84px;
      }
    }
    .block-objects {
      .title {
        font-size: 30px;
        letter-spacing: 1.5px;
      }
      .list-objects .item-object p {
        font-size: 16px;
      }
    }
  }
  @media (min-width: 1600px) {
    .block-text {
      .title {
        font-size: 85px;
      }
    }
    .block-objects {
      .title {
        font-size: 38px;
      }
    }
  }
}
.section-right5 {
  p {
    .text-plain;
  }
  .block-adress {
    margin-bottom: 5vh;
    font-size: 13px;
  }
  .block-phone {
    margin-bottom: 5vh;
    p {
      font-size: 18px;
    }
  }
  @media (min-width: @screen-sm-min) {
    .block-adress {
      font-size: 16px;
    }
    .block-phone {
      p {
        font-size: 22px;
      }
    }
  }
  @media (min-width: @screen-lg-min) {
    .block-adress {
      font-size: 18px;
    }
    .block-phone {
      p {
        margin-bottom: 10px;
        font-size: 30px;
        letter-spacing: 1.97px;
      }
    }
  }
}

// --------------------------------- modal styles
.icon-close {
  .maker-icon-close(45px, 2px, #eff2e5);
}
@arrow-width: 30px;
@arrow-border-width: 1px;
@arrow-color: #ffffff;
.arrow-left {
  .make-arrow-left(@arrow-width, @arrow-border-width, @arrow-color);
}
.arrow-right {
  .make-arrow-right(@arrow-width, @arrow-border-width, @arrow-color);
}

.md-close {
  position: absolute;
  top: 10px;
  right: 10px;
  display: block;
  padding: 0;
  background: transparent;
  box-shadow: none;
  cursor: pointer;
  &:hover, &:focus, &:active {
    box-shadow: none;
    .icon-close {
      &:before, &:after {
        background-color: grey;
      }
    }
  }
}
#modal-object {
  .modal-dialog {
    width: 80%;
    max-width: 1200px;
    margin-top: 5%;
    margin-right: auto;
    margin-left: auto;
    .modal-content {
      background-color: transparent;
      border: none;
      border-radius: 0;
    }
    .modal-body {
      padding-top: 60px;
    }
  }
}


.block-gallery-photo {
  .title-block {
    margin-bottom: 25px;
    color: #ffffff;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 1px;
  }
}


/* Js carousel */

.jcarousel-wrapper {
  width: 100%;
  margin: 20px auto;
  position: relative;
}

/** Carousel **/

.jcarousel {
  position: relative;
  overflow: hidden;
  width: 80%;
  margin: 0 auto;
}

.jcarousel ul {
  width: 10000em;
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
}

.jcarousel li {
  width: 200px !important;
  float: left;
  border: 1px solid #000;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.jcarousel img {
  display: block;
  max-width: 100%;
  height: auto !important;
}


/** Carousel Controls **/
.jcarousel-control-prev,
.jcarousel-control-next {
  position: absolute;
  top: 50%;
  margin-top: -15px;
  width: 30px;
  height: 30px;
  text-align: center;
}
.jcarousel-control-prev {
  left: 0;
  .icon-arrow-left {
    .make-arrow-left(20px, 2px, #666666);
  }
}
.jcarousel-control-next {
  right: 0;
  .icon-arrow-right {
    .make-arrow-right(20px, 2px, #666666);
  }
}

/** Carousel Pagination **/

.jcarousel-pagination {
  position: absolute;
  bottom: -40px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  margin: 0;
}

.jcarousel-pagination a {
  text-decoration: none;
  display: inline-block;

  font-size: 11px;
  height: 10px;
  width: 10px;
  line-height: 10px;

  background: #fff;
  color: #4E443C;
  border-radius: 10px;
  text-indent: -9999px;

  margin-right: 7px;


  -webkit-box-shadow: 0 0 2px #4E443C;
  -moz-box-shadow: 0 0 2px #4E443C;
  box-shadow: 0 0 2px #4E443C;
}

.jcarousel-pagination a.active {
  background: #4E443C;
  color: #fff;
  opacity: 1;

  -webkit-box-shadow: 0 0 2px #F0EFE7;
  -moz-box-shadow: 0 0 2px #F0EFE7;
  box-shadow: 0 0 2px #F0EFE7;
}
